.add-edit-section {
  .card-wrapper {
    justify-content: center;

    .form-wrapper {
      width: 50%;
      margin: auto;
      padding: 1rem 0rem;
      input[type="file"] {
        display: none;
      }
    }

    .inputs {
      width: 40%;
    }

    .prop-label {
      text-align: left;
      font-size: 16px;
      color: var(--clr-text-100);
      margin-bottom: 0.5rem !important;
    }

    .actions {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .tool-comp {
    p {
      margin-bottom: 0px;
    }
  }

  .tool {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }

  .tooltiptext {
    visibility: hidden;
    width: 100px;
    height: 70px;
    background-color: #050505;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: -100%;
    left: 162%;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 13px;
  }

  .tool .tooltiptext::after {
    content: "";
    position: absolute;
    top: 35%;
    left: -5%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(90deg);
  }

  .tool:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 796px) {
  .form-wrapper {
    width: 100% !important;
  }
}
