.assign-role{
    height: 200px;
          overflow-y: auto;
    .role-div{
        margin-left: 10px;
   
        display: inline;
    padding-bottom: .2rem;
        
    }
}