@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: local('Lato Thin'), local('Lato-Thin'), url(./Lato-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    src: local('Lato ThinItalic.'), local('Lato-Thin'), url(./Lato-ThinItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local('Lato Light'), local('Lato-Light'), url(./Lato-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: local('Lato LightItalic'), local('Lato-LightItalic'), url(./Lato-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(./Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'), url(./Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'), url(./Lato-Bold.ttf) format('truetype');

}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: local('Lato BoldItalic'), local('Lato-BoldItalic'), url(./Lato-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: local('Lato Black'), local('Lato-Black'), url(./Lato-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    src: local('Lato BlackItalic'), local('Lato-BlackItalic'), url(./Lato-BlackItalic.ttf) format('truetype');
}