/*----------CLUSTERS CCS STARTS----------------------*/
.dp-cluster-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.btn-icon {
  margin: 0px 6px 0px 0px;
  font-size: 18px !important;
}

.dp-sep-h {
  display: none;
}

.dp-sep-hm {
  display: none;
  width: 100%;
  height: 1px;
  background-color: #4e4e4e;
}


.dp-cluster-table-section {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #3B3B3B;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.dp-cluster-table-cont {
  padding: 8px;
}

.dp-cluster-table-input-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button-center {
  justify-content: center;
}

.dp-search-cont {
  display: flex;
  width: auto;
  align-items: center;
  padding: 0.8rem 0.5rem;
  height: 100%;
  box-sizing: border-box;
}

.dp-search-cont input {
  padding: 0.4rem;
  min-width: 280px;
  border: 1px solid #8D8D8D;
  border-radius: 4px;
  background-color: #3B3B3B;
  color: #A7A7A7;
}

.dp-search-cont input:focus {
  outline: none !important;
  border: 1px solid #0066FF;
  background-color: #474747;
}

.dp-filter-cont {
  display: flex;
  width: auto;
  align-items: flex-start;
  padding: 0.8rem 0.5rem;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.dp-filter-cont label {
  padding: 0rem 0.5rem 0.5rem 0rem;
  font-size: 0.8rem;
  font-weight: 700;
  color: #8D8D8D;
}

.dp-filter-cont select {
  padding: 0.4rem;
  min-width: 280px;
  border: 1px solid #8D8D8D;
  border-radius: 4px;
  background-color: #3B3B3B;
  color: #A7A7A7;
}

.dp-filter-cont select:focus {
  outline: none !important;
  border: 1px solid #0066FF;
  background-color: #474747;
}

.dp-search-contdp-cluster-table-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 6px;
}

.data-cont {
  width: 100%
}


/*----------CLUSTERS CCS ENDS----------------------*/

@media screen and (max-width: 950px) {

  .action-icon {
    right: 14px !important;
    /*background-color: #2A2A2A;*/
  }

  /*.action-icon:hover {
      right: 14px !important;
      background-color: #3B3B3B;
    }*/

  .data-cont {
    width: 60%
  }
}



@media only screen and (max-width: 650px) {
  .dp-cluster-table-input-cont {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }

  .dp-search-cont {
    display: flex;
    width: 100%;
  }

  .dp-search-cont input {
    width: 100%;
  }

  .dp-filter-cont {
    width: 100%;
  }

  .dp-filter-cont select {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {

  .dp-jobs-card {
    width: auto;
  }

  .dp-cluster-card {
    width: auto;
  }

  .dp-admin-prop-cont {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dp-table-cont {
    width: 80%;
  }
}

@media only screen and (max-width: 390px) {
  .dp-section-data-group-1 {
    padding: 0px 0px 0px 8px;
  }

  .dp-section-data-group-2 {
    padding: 0px 8px 0px 0px;
  }

  .dp-breadcrumb {
    margin: 0px 0px 0px 10px;
  }

  .dp-menu-icon {
    margin: 0px 4px 0px 0px;
  }
}

@media only screen and (max-width: 375px) {
  .dp-section-data-group-1 {
    padding: 0px 0px 0px 0px;
  }

  .dp-section-data-group-2 {
    padding: 0px 0px 0px 0px;
  }
}