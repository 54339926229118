@import "../src/app/utils/global_colors.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #1a1919 #5b5b5b;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 1px solid grey;

}

.apexcharts-toolbar {
  z-index: 0 !important;
  display: none !important;
}

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  --bs-gutter-x: .5rem !important;
  /* margin-right: -12px;
  margin-left: -12px; */
}

.sideBar-icons {
  font-size: 1.6rem;
}

.text_color {
  color: var(--clr-text-100) !important;
}

.pieDisable {
  pointer-events: none;
  opacity: 0.7;
}

.modal-content {
  background-color: var(--clr-card-100) !important;
  border: 1px solid var(--clr-card-200) !important;
  border-radius: 11px !important;
  color: var(--clr-text-300)
}

.modal-header .btn-close {
  filter: invert() !important;
}

.text_color_main {
  color: var(--clr-text-300)
}

.pieChart {
  height: 200px !important;
  width: 350px !important;
}

.pieChart-legend-label {
  color: var(--clr-text-300) !important;
}

.barGraph {
  width: 450px;
  height: 300px
}

.err-msg {
  color: red;
  margin-top: -0.5rem;
}

.text_primary {
  color: var(--clr-text-300);
}

.form-control {
  border: 1px solid var(--clr-input-border) !important;
  border-radius: 4px !important;
  background-color: var(--clr-card-100) !important;
  color: var(--clr-text-300) !important;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid var(--clr-input-border-focus) !important;
  background-color: var(--clr-card-200) !important;
  box-shadow: unset !important;
}

.form-control:disabled {
  background-color: var(--clr-input-background-disable) !important;
  cursor: not-allowed ! important;
}

.form-select {
  border: 1px solid #8D8D8D !important;
  border-radius: 4px !important;
  background-color: #3B3B3B !important;
  color: #A7A7A7 !important;
}

.form-select:focus {
  outline: none !important;
  border: 1px solid #0066FF !important;
  background-color: #474747 !important;
  box-shadow: unset !important;
}

.form-select:disabled {
  background-color: #3B3B3B !important;
  cursor: not-allowed ! important;
}

.form-select.light-border {
  border: 1px solid #444444 !important;
}

/* Action Menu */

.MuiMenu-paper {
  background-color: var(--clr-card-200) !important;
  color: var(--clr-text-300) !important;
}

.MuiMenu-paper li:hover {
  background-color: var(--clr-card-300) !important;
  color: var(--clr-text-300) !important;
}


.id-input {
  background: transparent !important;
}

.mr-10 {
  margin-right: 10px;
}

.vis-hidden {
  visibility: hidden;
}

.mw-190 {
  min-width: 178px;
}

.modal-header {
  border-bottom: 1px solid #545454 !important;
}

.modal-footer {
  border-top: 1px solid #545454 !important;
}

.disabled-items {
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: .65;

}

.text-wrap {
  word-break: break-all;
  white-space: normal;
}