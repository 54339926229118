$white: #ffffff;
$black: #000000;
$lightGray: #e6e6e6;
$grayShade1:#f9fafa;
$pattensBlue:#dddfe1;
$mischka:#abafb3;
$slategray:#78828C;
$resolutionBlue:#093F7C;
$whisper:#eeeeee;
$neonBlue:#623FF1;
$hanPurple: #5833f1;
$mediumSlateBlue:#7F63F4;
$darkGray:#aaaaaa;
$tuna:#464A53;
$HanPurple:#441cef;
$greenYellow:#adff2f;