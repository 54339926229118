:root {
    /* Constant colors */
    --clr-success: #059100;
    --clr-warning: #ef8700;
    --clr-danger: #ff3d00;
    --clr-disable: #9D9D9D;

    --clr-primary-300: #81bbff;
    --clr-primary-500: #0066ff;

    --clr-shadow: #00000029;

    /* End constant colors */

    /* Data */
    --data-logo: url("../assets/logo/datapelago-logo-black.png");
    --clr-filter-menu-btn: invert(70%) brightness(100%);

    /* Text */
    --clr-text-100: #121212;
    --clr-text-300: #2A2A2A;
    --clr-text-500: #545454;

    /* Background */
    --clr-background-50: #FFFFFF;
    --clr-background-100: #F8FBFF;
    --clr-background-200: #ebebeb;
    --clr-background-300: #e0e0e0;

    /* Card */
    --clr-card-100: #FFFFFF;
    --clr-card-200: #E8E8E8;
    --clr-card-300: #BBBBBB;

    /* Border */
    --clr-border-50: #E8E8E8;
    --clr-border-100: #BBBBBB;
    --clr-border-200: #777777;
    --clr-border-500: #595959;
    --clr-border-800: #333333;

    /* Table */
    --clr-table-background: var(--clr-card-100);
    --clr-table-background-even: var(--clr-card-200);
    --clr-table-text-head: var(--clr-text-100);
    --clr-table-text: var(--clr-text-300);
    --clr-table-text-even: var(--clr-text-300);
    --clr-table-text-id: var(--clr-primary-500);
    --clr-table-border: var(--clr-border-50);

    /* Other */

    --clr-details-label: #3c3b3b;

    --clr-crumbs-text: #3b3b3b;

    --clr-disabled-btn: #b3b2b2;
    --clr-notification: #717070;
    --clr-notification-selected: #000000;

    /* Input */
    --clr-input-background: #FFFFFF;
    --clr-input-background-disable: #E8E8E8;
    --clr-input-text: var(--clr-text-300);
    --clr-input-placeholder: #2A2A2A;
    --clr-input-border: #595959;
    --clr-input-border-focus: var(--clr-primary-500);

    /* Header */
    --clr-header-background: #ffffff;
    --clr-header-text: #2A2A2A;
    --clr-header-menu-background: #FFFFFF;
    --clr-header-menu-text: #2A2A2A;
    --clr-header-border: #E8E8E8;

    /* Sidebar */
    --clr-sidebar-background: #FFFFFF;
    --clr-sidebar-tile: var(--clr-primary-500);
    --clr-sidebar-tile-text: #2A2A2A;
    --clr-sidebar-tile-text-active: #FFFFFF;
    --clr-sidebar-tile-submenu-text-active: #FFFFFF;

    /* Body */
    --clr-body-background: #E8E8E8;
    --clr-body-text: #202020;

    /* Footer */
    --clr-footer-background: var(--clr-card-100);
    --clr-footer-text: var(--clr-text-300);
    --clr-footer-text-active: var(--clr-primary-500);
    --clr-footer-border: var(--clr-card-100);

    /* Buttons */
    --clr-primary-btn-background: var(--clr-primary-500);
    --clr-primary-btn-background-hover: #FFFFFF;
    --clr-primary-btn-text: #FFFFFF;
    --clr-primary-btn-text-hover: var(--clr-primary-500);
    --clr-primary-btn-border: var(--clr-primary-500);
    --clr-primary-btn-border-hover: var(--clr-primary-500);

    --clr-secondary-btn-background: #FFFFFF;
    --clr-secondary-btn-background-hover: var(--clr-primary-500);
    --clr-secondary-btn-text: var(--clr-primary-500);
    --clr-secondary-btn-text-hover: #FFFFFF;
    --clr-secondary-btn-border: var(--clr-primary-500);
    --clr-secondary-btn-border-hover: var(--clr-primary-500);

    --clr-outline-btn-background: transparent;
    --clr-outline-btn-background-hover: var(--clr-primary-500);
    --clr-outline-btn-text: var(--clr-text-100);
    --clr-outline-btn-text-hover: #FFFFFF;
    --clr-outline-btn-border: var(--clr-border-500);
    --clr-outline-btn-border-hover: var(--clr-border-500);

    --clr-disable-btn-background: var(--clr-card-200);
    --clr-disable-btn-text: var(--clr-text-300);
    --clr-disable-btn-border: var(--clr-border-500);

    --clr-rounded-btn: var(--clr-primary-500);
    --clr-rounded-btn-disable: var(--clr-disable-btn-text);
}

:root .theme--light {
    --data-logo: url("../assets/logo/datapelago-logo-black.png");
    --clr-filter-menu-btn: invert(70%) brightness(100%);

    --clr-primary-300: #5D9EFF;
    --clr-primary-500: #2A6BCC;

    /* Text */
    --clr-text-100: #121212;
    --clr-text-300: #2A2A2A;
    --clr-text-500: #545454;

    /* Background */
    --clr-background-50: #FFFFFF;
    --clr-background-100: #F8FBFF;
    --clr-background-200: #ebebeb;
    --clr-background-300: #e0e0e0;

    /* Card */
    --clr-card-100: #FFFFFF;
    --clr-card-200: #E8E8E8;
    --clr-card-300: #BBBBBB;

    /* Border */
    --clr-border-50: #E8E8E8;
    --clr-border-100: #BBBBBB;
    --clr-border-200: #777777;
    --clr-border-500: #595959;
    --clr-border-800: #333333;

    /* Table */
    --clr-table-background: var(--clr-card-100);
    --clr-table-background-even: var(--clr-card-200);
    --clr-table-text-head: var(--clr-text-100);
    --clr-table-text: var(--clr-text-300);
    --clr-table-text-even: var(--clr-text-300);
    --clr-table-text-id: var(--clr-primary-500);
    --clr-table-border: var(--clr-border-50);

    /* Other */

    --clr-details-label: #3c3b3b;

    --clr-crumbs-text: #3b3b3b;

    --clr-disabled-btn: #b3b2b2;
    --clr-notification: #717070;
    --clr-notification-selected: #000000;

    /* Input */
    --clr-input-background: #FFFFFF;
    --clr-input-background-disable: #E8E8E8;
    --clr-input-text: var(--clr-text-300);
    --clr-input-placeholder: #2A2A2A;
    --clr-input-border: #595959;
    --clr-input-border-focus: var(--clr-primary-500);

    /* Header */
    --clr-header-background: #ffffff;
    --clr-header-text: #2A2A2A;
    --clr-header-menu-background: #FFFFFF;
    --clr-header-menu-text: #2A2A2A;
    --clr-header-border: #E8E8E8;

    /* Sidebar */
    --clr-sidebar-background: #FFFFFF;
    --clr-sidebar-tile: var(--clr-primary-500);
    --clr-sidebar-tile-text: #2A2A2A;
    --clr-sidebar-tile-text-active: #FFFFFF;
    --clr-sidebar-tile-submenu-text-active: #FFFFFF;

    /* Body */
    --clr-body-background: #E8E8E8;
    --clr-body-text: #202020;

    /* Footer */
    --clr-footer-background: var(--clr-card-100);
    --clr-footer-text: var(--clr-text-300);
    --clr-footer-text-active: var(--clr-primary-500);
    --clr-footer-border: var(--clr-card-100);

    /* Buttons */
    --clr-primary-btn-background: var(--clr-primary-500);
    --clr-primary-btn-background-hover: #FFFFFF;
    --clr-primary-btn-text: #FFFFFF;
    --clr-primary-btn-text-hover: var(--clr-primary-500);
    --clr-primary-btn-border: var(--clr-primary-500);
    --clr-primary-btn-border-hover: var(--clr-primary-500);

    --clr-secondary-btn-background: #FFFFFF;
    --clr-secondary-btn-background-hover: var(--clr-primary-500);
    --clr-secondary-btn-text: var(--clr-primary-500);
    --clr-secondary-btn-text-hover: #FFFFFF;
    --clr-secondary-btn-border: var(--clr-primary-500);
    --clr-secondary-btn-border-hover: var(--clr-primary-500);

    --clr-outline-btn-background: transparent;
    --clr-outline-btn-background-hover: var(--clr-primary-500);
    --clr-outline-btn-text: var(--clr-text-100);
    --clr-outline-btn-text-hover: #FFFFFF;
    --clr-outline-btn-border: var(--clr-border-500);
    --clr-outline-btn-border-hover: var(--clr-border-500);

    --clr-disable-btn-background: var(--clr-card-200);
    --clr-disable-btn-text: var(--clr-text-300);
    --clr-disable-btn-border: var(--clr-border-500);

    --clr-rounded-btn: var(--clr-primary-500);
    --clr-rounded-btn-disable: var(--clr-disable-btn-text);
}

:root .theme--dark {
    --data-logo: url("../assets/logo/datapelago-logo-white.png");
    --clr-filter-menu-btn: invert(0%) brightness(100%);

    --clr-primary-300: #66A3FF;
    --clr-primary-500: #0066FF;

    /* Text */
    --clr-text-100: #FFFFFF;
    --clr-text-300: #E8E8E8;
    --clr-text-500: #acacac;

    /* Background */
    --clr-background-50: #121212;
    --clr-background-100: #262626;
    --clr-background-200: #2e2e2e;
    --clr-background-300: #363636;

    /* Card */
    --clr-card-100: #2A2A2A;
    --clr-card-200: #363636;
    --clr-card-300: #303030;

    /* Border */
    --clr-border-50: #333333;
    --clr-border-100: #595959;
    --clr-border-200: #777777;
    --clr-border-500: #BBBBBB;
    --clr-border-800: #E8E8E8;

    /* Table */
    --clr-table-background: var(--clr-card-100);
    --clr-table-background-even: var(--clr-card-200);
    --clr-table-text-head: var(--clr-text-100);
    --clr-table-text: var(--clr-text-300);
    --clr-table-text-even: var(--clr-text-300);
    --clr-table-text-id: var(--clr-primary-500);
    --clr-table-border: var(--clr-border-50);

    /* Other */
    --clr-details-label: #acacac;

    --clr-crumbs-text: #818181;

    --clr-disabled-btn: #f1eded;
    --clr-notification: #a7a7a7;
    --clr-notification-selected: #edeaea;

    /* Input */
    --clr-input-background: var(--clr-card-100);
    --clr-input-background-disable: var(--clr-card-200);
    --clr-input-text: var(--clr-text-300);
    --clr-input-placeholder: var(--clr-text-500);
    --clr-input-border: var(--clr-border-200);
    --clr-input-border-focus: var(--clr-primary-500);

    /* Header */
    --clr-header-background: #2A2A2A;
    --clr-header-text: #f1f1f1;
    --clr-header-menu-background: #3b3b3b;
    --clr-header-menu-text: #eeeeee;
    --clr-header-border: #262626;

    /* Sidebar */
    --clr-sidebar-background: #2A2A2A;
    --clr-sidebar-tile: var(--clr-primary-500);
    --clr-sidebar-tile-text: #E8E8E8;
    --clr-sidebar-tile-text-active: #FFFFFF;
    --clr-sidebar-tile-submenu-text-active: #FFFFFF;

    /* Body */
    --clr-body-background: #121212;
    --clr-body-text: #202020;

    /* Footer */
    --clr-footer-background: var(--clr-card-100);
    --clr-footer-text: var(--clr-text-300);
    --clr-footer-text-active: var(--clr-primary-500);
    --clr-footer-border: var(--clr-card-100);

    /* Buttons */
    --clr-primary-btn-background: var(--clr-primary-500);
    --clr-primary-btn-background-hover: #FFFFFF;
    --clr-primary-btn-text: #FFFFFF;
    --clr-primary-btn-text-hover: var(--clr-primary-500);
    --clr-primary-btn-border: var(--clr-primary-500);
    --clr-primary-btn-border-hover: var(--clr-primary-500);

    --clr-secondary-btn-background: #FFFFFF;
    --clr-secondary-btn-background-hover: var(--clr-primary-500);
    --clr-secondary-btn-text: var(--clr-primary-500);
    --clr-secondary-btn-text-hover: #FFFFFF;
    --clr-secondary-btn-border: var(--clr-primary-500);
    --clr-secondary-btn-border-hover: var(--clr-primary-500);

    --clr-outline-btn-background: transparent;
    --clr-outline-btn-background-hover: var(--clr-primary-500);
    --clr-outline-btn-text: var(--clr-text-100);
    --clr-outline-btn-text-hover: #FFFFFF;
    --clr-outline-btn-border: var(--clr-border-500);
    --clr-outline-btn-border-hover: var(--clr-border-500);

    --clr-disable-btn-background: var(--clr-card-300);
    --clr-disable-btn-text: var(--clr-text-300);
    --clr-disable-btn-border: var(--clr-border-200);

    --clr-rounded-btn: var(--clr-primary-300);
    --clr-rounded-btn-disable: var(--clr-disable-btn-text);
}