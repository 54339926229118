@import "~react-pro-sidebar/dist/scss/styles.scss";
@import "../../utils/global_colors.scss";

$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;
$breakpoint-md: 1140px;

@import "~react-pro-sidebar/dist/scss/styles.scss";

body {
  margin: 0;
  color: #353535;
  font-family: "Lato", sans-serif;
}

.app {
  display: flex;
  position: relative;
  background-color: var(--clr-body-background) !important;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.pro-sidebar > .pro-sidebar-inner {
  min-height: 700px;
  background-color: var(--clr-sidebar-background) !important;
  color: var(--clr-sidebar-text);
  position: fixed !important;
  ::-webkit-scrollbar {
    display: none;
  }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: transparent !important;
  padding-left: 33px;
  text-align: left;
  width: 170px;
}

.pro-sidebar.md.collapsed > .pro-sidebar-inner {
  min-height: 700px;
  width: 80px;
  position: fixed !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: auto !important;
  text-overflow: ellipsis;
  white-space: normal !important;
  font-size: 1rem !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  width: 200px;
  padding: 8px 30px 8px 9px !important;
  background-color: transparent;
  color: var(--clr-sidebar-tile-text) !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item {
  color: var(--clr-sidebar-tile-text-active) !important;
}

.pro-sidebar.collapsed .pro-menu .pro-menu-item > .pro-inner-item {
  width: unset;
}

.pro-sidebar-content {
  margin-top: 56px;
}

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
//   position: fixed !important;
// }

.content {
  margin: 5rem 1.2rem;
}

.flow-y {
  overflow-y: scroll;
  height: 100vh;
}

.app main {
  background: var(--clr-body-background);
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid var(--clr-sidebar-background);
  z-index: 999;
}

.pro-sidebar {
  width: 200px !important;
  min-width: 200px !important;
  background-color: var(--clr-sidebar-background) !important;
}

.pro-sidebar.collapsed {
  width: 80px !important;
  min-width: 80px !important;
  background-color: var(--clr-sidebar-background) !important;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  overflow-y: auto;
  background-color: var(--clr-sidebar-background) !important;
  padding-left: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: var(--clr-sidebar-tile-text-active) !important;
  background-color: var(--clr-sidebar-tile) !important;
}

.pro-sidebar .pro-menu {
  padding-top: unset !important;
  padding-bottom: 10px;
}

.header {
  // padding: 10px 0px 12px;
  background: #000000;
  position: fixed;
  top: 0px;
  z-index: 5;
  width: 100%;

  // position: fixed;
  .header-content {
    margin: 0 auto;
    color: white;
    display: flex;
    justify-content: space-between;
    background: #000000;
    .left-header {
      float: left;
      margin-left: 210px;
    }
    .left-header-collapsed {
      float: left;
      margin-left: 80px;
    }
    .right-header {
      float: right;
      justify-content: flex-end;
      display: flex;
    }
    .cursors {
      cursor: pointer;
    }
    .drop {
      display: flex;

      align-items: center;
      cursor: pointer;
    }
  }

  .hamburger {
    font-size: 30px;
  }
}

.app .btn-toggle {
  margin-top: 12px;
  background-color: unset;
}

@media screen and (max-width: 768px) {
  .brand-logo {
    display: flex;
  }

  .pro-sidebar-content {
    margin-top: unset;
  }

  // .pro-sidebar > .pro-sidebar-inner {
  //   top: 60px;
  // }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    font-size: 10px;
  }

  .pro-sidebar {
    width: 160px !important;
    min-width: 160px !important;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    width: 160px;
    color: var(--clr-sidebar-tile-text) !important;
  }

  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item.active {
    width: fit-content;
    background-color: var(--clr-sidebar-background) !important;
    color: var(--clr-sidebar-tile-text-active) !important;
  }
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  width: max-content;
  background-color: var(--clr-sidebar-tile) !important;
  color: var(--clr-sidebar-tile-text-active) !important;
}

.pro-sidebar .pro-menu .pro-menu-item.active .pro-item-content {
  color: var(--clr-sidebar-tile-text-active) !important;
}

.pro-sidebar.collapsed .pro-menu .pro-menu-item.active .pro-item-content {
  color: var(--clr-sidebar-tile) !important;
}

.pro-sidebar.collapsed .pro-menu .pro-menu-item.pro-sub-menu .pro-menu-item {
  width: 100%;
}

.pro-sidebar.collapsed
  .pro-menu
  .pro-sub-menu
  .pro-menu-item.active
  .pro-item-content {
  color: var(--clr-sidebar-tile-text-active) !important;
}

// .pro-sidebar.collapsed .pro-menu .pro-menu-item.active {
//   width: 80px;
// }

.pro-sidebar .pro-menu .pro-menu-item.inactive {
  color: var(--clr-sidebar-tile-text) !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border-top: none;
  height: 50px;
  background-color: var(--clr-footer-background);
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100vh !important;
}
