/* Buttons */

.buttonX {
    width: max-content;
    padding: 0.5em 1em;
    background-color: var(--clr-primary-btn-background);
    color: var(--clr-primary-btn-text);
    border: 1px solid var(--clr-primary-btn-border);
    border-radius: 0.4rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.4s;
}

.buttonX:hover {
    background-color: var(--clr-primary-btn-background-hover);
    color: var(--clr-primary-btn-text-hover);
    border: 1px solid var(--clr-primary-btn-border-hover);
    transition-duration: 0.4s;
}

.buttonX:disabled {
    background-color: var(--clr-disable-btn-background) !important;
    color: var(--clr-disable-btn-text) !important;
    border: 1px var(--clr-disable-btn-border) solid !important;
    cursor: not-allowed;
    opacity: 0.5;
}

.buttonX.white {
    background-color: var(--clr-secondary-btn-background);
    color: var(--clr-secondary-btn-text);
    border: 1px solid var(--clr-secondary-btn-border);
}

.buttonX.white:hover {
    background-color: var(--clr-secondary-btn-background-hover);
    color: var(--clr-secondary-btn-text-hover);
    border: 1px solid var(--clr-secondary-btn-border-hover);
}

.buttonX.outline {
    background-color: var(--clr-outline-btn-background);
    color: var(--clr-outline-btn-text);
    border: 1px solid var(--clr-outline-btn-border);
}

.buttonX.outline:hover {
    background-color: var(--clr-outline-btn-background-hover);
    color: var(--clr-outline-btn-text-hover);
    border: 1px solid var(--clr-outline-btn-border-hover);
}

.buttonX i {
    margin-right: 0.5rem;
}


.buttonX.details-button {
    width: 14rem;
}

/* Text Style */

.text-underline-hover {
    text-decoration: none;
}

.text-underline-hover:hover {
    text-decoration: underline;
}

/* Cursor Style */

.cursor-pointer {
    cursor: pointer;
}

/* Input Fields */

.input-boxes {
    height: 3rem;
    width: 50%;
    background-color: var(--clr-input-background);
    color: var(--clr-input-text) !important;
    border: 1px solid var(--clr-input-border);
    border-radius: 4px;
    padding: .25rem 1rem;
    min-height: 38px;
}

.input-boxes:focus {
    background-color: var(--clr-input-background) !important;
    color: var(--clr-input-text) !important;
    border: 1px solid var(--clr-input-border-focus) !important;
    outline: none !important;
    box-shadow: unset !important;
}

@media (max-width:786px) {
    .input-boxes {
        width: 100% !important;
    }
}

[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-color: #acacac;
    -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    background-size: 1rem 1rem;
    height: 1rem;
    width: 1rem;
}

.props-box {
    border-radius: 8px;
}

.props-box .add-icon {
    color: var(--clr-rounded-btn) !important;
    font-size: 1.5rem;
    cursor: pointer;
}

.props-box .close-icon {
    margin-right: 10px;
    padding-left: 0px;
    color: var(--clr-rounded-btn);
    font-size: 1.5rem;
    cursor: pointer;
}

.props-box .disable {
    margin-right: 10px;
    color: var(--clr-rounded-btn-disable);
    font-size: 1.5rem;
    cursor: not-allowed;
}

/* Tabs Style */

.tabX-tab-head {
    font-weight: bold !important;
    color: var(--clr-text-300) !important;
    flex: 1;
}

.tabX-tab-head.active {
    color: var(--clr-primary-500) !important;
    border-bottom: 2px solid var(--clr-primary-500) !important;
}

.tabX-tab-body>div {
    padding: 0 !important;
}