.user-assign-roles-section {
  .role-tile {
    padding: 0.5rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid var(--clr-border-100);

    > p {
      margin-bottom: 0;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--clr-primary-300);
        text-decoration: underline;
      }
    }

    &:last-child {
      border: 0;
    }
  }
}
