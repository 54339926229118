.dp-wrapper {
	display: block;
	clear: both;
	float: none;
	margin: 0px auto;
	padding: 0px;
	width: 100%;
}

.dp-menu-box {
	background-color: var(--clr-header-menu-background);
	border: 1px solid var(--clr-header-border);
	position: absolute;
	width: 150px;
	right: 30px;
	top: 60px;
	border-radius: 8px;
	padding: 0.5rem;
}


.dp-notification-count {
	position: relative;
	padding: 0rem 0.3rem;
	left: 26px;
	top: 11px;
	background: #81BBFF;
	height: 18px;
	font-size: 12px;
	border-radius: 50%;
	width: 18px;
	color: #000000;
}

.dp-notification-mobile-count {
	padding: 0rem 0.3rem;
	left: 26px;
	top: 11px;
	background: #81BBFF;
	height: 18px;
	font-size: 12px;
	border-radius: 50%;
	width: 18px;
	color: #000000;
}

.dp-subMenu-box {
	background-color: var(--clr-header-menu-background);
	border: 1px solid var(--clr-header-border);
	position: absolute;
	width: 150px;
	right: 185px;
	top: 85px;
	border-radius: 8px;
	padding: 0.5rem;
}

.dp-subMenu-box .menu {
	cursor: pointer;
	margin-bottom: .5rem;
	color: var(--clr-header-menu-text) !important;
	font-size: 15px;
}

.dp-menu-box .menu {
	cursor: pointer;
	color: var(--clr-header-menu-text) !important;
	font-size: 15px;
}

.dp-mobile-menu {
	background-color: var(--clr-header-menu-background);
	border: 1px solid var(--clr-header-border);
	position: absolute;
	width: 200px;
	right: 30px;
	top: 60px;
	border-radius: 8px;
	padding: 0.5rem;

}


.dp-notifi {
	background-color: var(--clr-header-menu-background);
	border: 1px solid var(--clr-header-border);
	position: absolute;
	width: 200px;
	right: 30px;
	top: 60px;
	border-radius: 8px;
	padding: 0.5rem;
	height: 150px;
	overflow: auto;
}

.dp-mobile-menu .menu {
	cursor: pointer;
	color: var(--clr-header-menu-text) !important;
	font-size: 12px;
	text-align: left;
	margin-bottom: .5rem;
}

.dp-mobile-icon {
	display: flex;
	align-items: center;
	margin-bottom: .5rem;
}

.dp-mobile-subMenu-box {
	background-color: var(--clr-header-menu-background);
	border: 1px solid var(--clr-header-border);
	position: absolute;
	width: 125px;
	right: 235px;
	top: 170px;
	border-radius: 8px;
	padding: 0.5rem;
	z-index: 1;
}

.dp-mobile-subMenu-box .menu {
	cursor: pointer;
	margin-bottom: .5rem;
	color: var(--clr-header-menu-text) !important;
	font-size: 12px;
	text-align: left;

}

.dp-mobile-menu .dp-date-time-marker {
	font-size: .7rem !important;
	margin-left: .5rem;
}

.dp-section {
	display: flex;
	padding: 0px;
	width: 100%;
	justify-content: space-between;
}

.dp-section-center {
	display: flex;
	width: 100%;
}

.dp-section-data {
	display: block;
	clear: both;
	float: left;
	margin: 0px 0px 0px 0px;
	padding: 0px;
	width: 100%;
}

.dp-sction-data-1 {
	display: flex;
}

.dp-sction-data-2 {
	display: flex;
	justify-content: flex-end;
}

#dp-section_content {
	background-color: #000000;
}

.dp-header {
	display: flex;
	height: 54px;
	width: 140px;
}

.dp-section-data-group-1 {
	display: flex;
	padding: 0px 0px 0px 15px;
}

.dp-section-data-group-2 {
	display: flex;
	padding: 0px 15px 0px 0px;
}

.dp-menu-icon {
	display: flex;
	width: 54px;
	height: 54px;
	background-image: url("../../assets/images/menu-icon.svg");
	filter: var(--clr-filter-menu-btn);
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0px 8px 0px 0px;
	cursor: pointer;
}

.dp-profile-icon {
	display: flex;
	width: 54px;
	height: 54px;
	background-image: url("../../assets/images/menu-icon.svg");
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0px 8px 0px 0px;
	cursor: pointer;
}

.dp-logo {
	display: flex;
	height: 54px;
	width: 140px;
	background-image: var(--data-logo);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
}

.dp-breadcrumb {
	display: flex;
	color: #fff;
	font-size: 1rem;
	justify-content: center;
	align-items: center;
	margin: 0px 0px 0px 24px;
}

.dp-user-name {
	display: flex;
	color: #fff;
	font-size: 1rem;
	justify-content: center;
	align-items: center;
	margin: 0px 0px 0px 8px;
}

.dp-profile-icon {
	display: flex;
	width: 54px;
	height: 54px;
	background-image: url("../../assets/images/user-icon.svg");
	background-size: 70%;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0px 8px 0px 8px;
}

.dp-right-menu-icon {
	display: none;
	width: 54px;
	height: 54px;
	background-image: url("../../assets/images/right-menu1.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
}

.dp-right-menu-icon:hover {
	background-image: url("../../assets/images/right-menu2.png");
	filter: var(--clr-filter-menu-btn);
}

.dp-right-icon-group {
	display: flex;
}

.dp-notification-icon {
	display: flex;
	width: 54px;
	height: 54px;
	background-image: url("../../assets/images/notification-icon.svg");
	background-size: 55%;
	background-repeat: no-repeat;
	background-position: center;
	margin: 0px 0px 0px 8px;
	cursor: pointer;
}

.dp-date-time-marker {
	display: flex;
	color: #fff;
	font-size: 1rem;
	justify-content: center;
	align-items: center;
}


@media only screen and (max-width: 990px) {
	.dp-right-icon-group {
		display: none !important;
	}

	.dp-menu-box {
		display: none;
	}

	.dp-subMenu-box {
		display: none;
	}

	.dp-right-menu-icon {
		display: flex;
	}

	.dp-logo {
		display: flex;
		height: 54px;
		width: 54px;
		background-image: url("../../assets/images/datapelago-logo.png");
		background-size: 84%;
	}
}

@media only screen and (min-width:990px) {
	.dp-mobile-menu {
		display: none;
	}

	.dp-mobile-subMenu-box {
		display: none;
	}
}

@media only screen and (max-width: 390px) {
	.dp-section-data-group-1 {
		padding: 0px 0px 0px 8px;
	}

	.dp-section-data-group-2 {
		padding: 0px 8px 0px 0px;
	}

	.dp-breadcrumb {
		margin: 0px 0px 0px 10px;
	}

	.dp-menu-icon {
		margin: 0px 4px 0px 0px;
	}

}

@media only screen and (max-width: 375px) {
	.dp-section-data-group-1 {
		padding: 0px 0px 0px 0px;
	}

	.dp-section-data-group-2 {
		padding: 0px 0px 0px 0px;
	}

}