.MuiFormControl-root {
  background-color: var(--clr-input-background);

  & .MuiInputLabel-root {
    margin-top: -0.4rem;
    color: var(--clr-input-text);

    &.MuiInputLabel-shrink {
      margin-top: 0;
      padding: 0 0.3rem 0 0.3rem;
    }

    &.Mui-focused {
      color: var(--clr-input-text);
    }

    &.Mui-disabled {
      color: var(--clr-input-text);
    }
  }
  & .MuiInputBase-root {
    color: var(--clr-input-text);

    &.Mui-disabled {
      background-color: var(--clr-input-background-disable);
    }
  }
  & .MuiInputBase-input {
    padding: 10px 14px;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: var(--clr-input-text);
    -webkit-text-fill-color: var(--clr-input-text);
    cursor: not-allowed;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid var(--clr-input-border);
      border-radius: 4px;
    }
    &.Mui-disabled fieldset {
      border: 1px solid var(--clr-input-border);
    }
    &:hover fieldset {
      border: 1px solid var(--clr-input-border);
    }
    &.Mui-focused fieldset {
      border: 1px solid var(--clr-input-border-focus);
    }
  }
  & .MuiSvgIcon-root {
    color: var(--clr-input-text);

    &.Mui-disabled {
      color: var(--clr-input-text);
    }
  }
  & .MuiIconButton-root {
    color: var(--clr-input-text);

    &.Mui-disabled {
      color: var(--clr-input-text);
    }
  }
}

.mui-checkbox.MuiCheckbox-root svg {
  color: var(--clr-input-text);
}


.mui-checkbox.MuiCheckbox-root.Mui-checked svg {
  color: var(--clr-input-border-focus);
}
